import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  WMSTileLayer,
  LayersControl,
  GeoJSON,
  useMap,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { TiledMapLayer } from "react-esri-leaflet";
import VectorTileLayer from "react-esri-leaflet/plugins/VectorTileLayer";
import * as WMS from "leaflet.wms";

const { BaseLayer } = LayersControl;

function App() {
  const [departamentos, setDepartamentos] = useState<any>();

  useEffect(() => {
    getGeoJson();
  }, []);

  function MyComponent() {
    const map = useMapEvents({
      click: () => {
        console.log("click");
        console.log(map.getPixelBounds());
      },
    });
    return null;
  }

  function GetFeatureInfoWms(props: any) {
    const { url, options, layers } = props;
    const map = useMap();

    // Add WMS source/layers

    const source = WMS.source(url, options);

    for (let name of layers) {
      source.getLayer(name).addTo(map);
    }

    return null;
  }

  const getGeoJson = async () => {
    const response = await fetch(
      `http://3.220.25.32:8080/geoserver/test_fing/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=test_fing%3ADepartamentos&maxFeatures=50&outputFormat=application%2Fjson`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let responseJson = await response.json();
    setDepartamentos(responseJson);
  };

  return (
    <MapContainer
      center={[-32.768, -56.123]}
      zoom={7}
      scrollWheelZoom={true}
      style={{ height: "650px" }}
    >
      <MyComponent />
      <LayersControl>
        <BaseLayer checked name="OpenStreetMap">
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        <BaseLayer name="Google Maps">
          <TileLayer url="https://maps.googleapis.com/maps/vt/lyrs=r&amp;x={x}&amp;y={y}&amp;z={z}" />
        </BaseLayer>
        <BaseLayer name="Esri World Street Map">
          <TileLayer
            attribution="Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012"
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
          />
        </BaseLayer>
        <BaseLayer name="Ortofotos IDE">
          <TileLayer url="https://mapas.ide.uy/geoserver-raster/gwc/service/wmts/rest/ortofotos:ORTOFOTOS_2019/raster/EPSG:3857/EPSG:3857:{z}/{y}/{x}?format=image/jpeg" />
        </BaseLayer>
        <BaseLayer name="ESRI World Imagery">
          <TileLayer
            attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
        </BaseLayer>
        <BaseLayer name="Google Satelite">
          <TileLayer url="https://mt0.google.com/vt/lyrs=y&amp;hl=en&amp;x={x}&amp;y={y}&amp;z={z}" />
        </BaseLayer>
        <BaseLayer name="Image tile">
          <TiledMapLayer url="https://sit.mvotma.gub.uy/arcgis/rest/services/MAPASBASE/MapaBaseVectorial/MapServer/" />
        </BaseLayer>
        <BaseLayer name="Vector tile">
          <VectorTileLayer url="https://sit.mvotma.gub.uy/arcgis/rest/services/Hosted/Mapa_Base_Vector_Tiles/VectorTileServer/" />
        </BaseLayer>

        {/* <WMSTileLayer
            url="http://localhost:8600/geoserver/test/wms?"
            version="1.1.0"
            opacity={1}
            transparent
            layers={"Departamentos"}
            format="image/png"
          /> */}
        <GetFeatureInfoWms
          layers={["Departamentos"]}
          options={{
            format: "image/png",
            transparent: "true",
            srs: "EPSG:4326",
            // attribution:
            //   "<a href='https://ows.terrestris.de/'>terrestris</a>",
            info_format: "text/html",
            feature_count: 20,
          }}
          url="http://localhost:8600/geoserver/test/wms?"
        />

        <LayersControl.Overlay checked name="Datos historicos">
          <WMSTileLayer
            url="http://3.220.25.32:8080/geoserver/test_fing/wms?"
            opacity={0.5}
            layers={"Datos_historicos"}
            format="image/png"
          />
          
          {/* <GetFeatureInfoWms
            layers={["Barrios_MVD", "Departamentos"]}
            options={{
              format: "image/png",
              transparent: "true",
              srs: "ESPG:4326",
              info_format: "text/html",
            }}
            url="http://localhost:8600/geoserver/test/wms?"
          /> */}
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Departamentos">
          <WMSTileLayer
            url="http://3.220.25.32:8080/geoserver/test_fing/wms?"
            opacity={0.5}
            layers={"Departamentos"}
            format="image/png"
          />
          
          {/* <GetFeatureInfoWms
            layers={["Barrios_MVD", "Departamentos"]}
            options={{
              format: "image/png",
              transparent: "true",
              srs: "ESPG:4326",
              info_format: "text/html",
            }}
            url="http://localhost:8600/geoserver/test/wms?"
          /> */}
        </LayersControl.Overlay>
        {departamentos && (
          <LayersControl.Overlay name="Departamento WFS">
            <GeoJSON
              data={departamentos}
              style={() => ({
                color: "#4a83ec",
                weight: 0.5,
                fillColor: "#1a1d62",
                fillOpacity: 1,
              })}
            />
          </LayersControl.Overlay>
        )}
      </LayersControl>
    </MapContainer>
  );
}

export default App;
